import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import Pricing from '../../Models/Pricing';
import PricingForm from '../../Forms/PricingForm';
import usePricingList from '../../hooks/use-pricing-list';
import {date} from '../../Utils';

const LABELS = Pricing.labels;

export default function PricingListPage(props) {
  const {supplier} = props; // supplier = instance
  const app = useContext(Context);
  const [search, setSearch] = useState('');

  const {
    records,
    filters,
    setFilters,
    total,
    onUpdate,
    loading,
  } = usePricingList({supplier});

  const columns = [
    {
      title: LABELS['supplier_name'],
      key: 'supplier_name',
      render: (record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            app.actions.setModal(
              <PricingForm record={record} onUpdate={() => onUpdate(search)} />,
            );
          }}>
          {record.supplier_name}
        </a>
      ),
    },
    {title: LABELS['process'], dataIndex: 'process', key: 'process'},
    {
      title: LABELS['description'],
      dataIndex: 'description',
      key: 'description',
    },
    {title: LABELS['unit'], dataIndex: 'unit', key: 'unit'},
    {title: LABELS['price'], dataIndex: 'price', key: 'price'},
    {
      title: LABELS['created'],
      key: 'created',
      render: (record) => date.format(record.created),
    },
  ];

  return (
    <Wrapper>
      <div className="action-bar">
        <Input
          value={search}
          placeholder="搜尋製程、服務內容"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => onUpdate(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        <Button
          type="primary"
          onClick={() => {
            app.actions.setModal(
              <PricingForm
                record={
                  new Pricing({
                    supplier: supplier.id,
                    supplier_name: supplier.name,
                    process: '印前',
                  })
                }
                onUpdate={() => onUpdate(search)}
              />,
            );
          }}>
          ＋
        </Button>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={records}
        rowKey="id"
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          setFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .action-bar {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background-color: #fafafa;

    & > button {
      margin-left: 10px;
    }
  }
`;
