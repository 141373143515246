import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {Context} from '../AppContext';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 10,
};

export default function usePricingList({supplier}) {
  // supplier = instance
  const app = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });

  const [total, setTotal] = useState(0);

  const getRecords = useCallback(
    async (search = '') => {
      if (!supplier) {
        return;
      }

      setLoading(true);
      try {
        const params = {
          query: {
            $or: [{process: {$regex: search}}, {description: {$regex: search}}],
            supplier: supplier.id,
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };
        let resp = await app.actions.getPricings(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    },
    [app.actions, filters, supplier],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return {
    records,
    filters,
    setFilters,
    total,
    onUpdate: getRecords,
    loading,
  };
}
