class Pricing {
  constructor(record) {
    const {
      id = '',
      supplier = '',
      supplier_name = '',
      process = '',
      description = '',
      unit = '',
      price = 0,
    } = record || {};
    this.id = id;
    this.supplier = supplier;
    this.supplier_name = supplier_name;
    this.process = process;
    this.description = description;
    this.unit = unit;
    this.price = price;
  }
}

Pricing.labels = {
  id: '牌價編號',
  supplier: '廠商編號', //（自動create)
  supplier_name: '廠商名稱',
  process: '製程',
  description: '服務內容',
  unit: '規格', //單位
  price: '單價',
  created: '建立日期',
};

module.exports = Pricing;
