import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Context} from '../../AppContext';
import Supplier from '../../Models/Supplier';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit, Add} from '@styled-icons/material';
import SupplierForm from '../../Forms/SupplierForm';
import PricingList from './PricingList';
import {previousPage} from '../../Utils';
import SupplierBilling from '../../Components/BillingTable/SupplierBilling';
const queryString = require('query-string');

const labels = Supplier.labels;

const dbg = {
  id: 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function SupplierDetailPage(props) {
  const {id = dbg.id} = queryString.parse(props.location.search); //order.id
  const app = useContext(Context);
  const [record, setRecord] = useState();

  const getRecord = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getSupplier({query: {id}});
      setRecord(resp);
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage(`/suppliers`)}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />
      </div>
      <Row gutter={[30, 30]}>
        {record && (
          <Col span={24}>
            <Block
              title="廠商資訊"
              actions={[
                {
                  icon: <Edit size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModalWidth(900);
                    app.actions.setModal(
                      <SupplierForm record={record} onUpdate={getRecord} />,
                    );
                  },
                },
              ]}>
              {/* <Field label={labels['id']}>{record.id}</Field> */}

              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['name']}>{record.name}</Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['company_title']}>
                    {record.company_title}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['uni_number']}>
                    {' '}
                    {record.uni_number}{' '}
                  </Field>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['tel']}> {record.tel} </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['fax']}> {record.fax} </Field>
                </Col>
              </Row>

              <Field label={labels['address']}> {record.address} </Field>

              <Field label={labels['email']}> {record.email} </Field>

              <Field label={labels['pay_period']}> {record.pay_period} </Field>

              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['bank_name']}>
                    {' '}
                    {record.bank_name}{' '}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['bank_branch']}>
                    {record.bank_branch}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['bank_account']}>
                    {record.bank_account}
                  </Field>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['contact_name1']}>
                    {record.contact_name1}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['contact_phone1']}>
                    {record.contact_phone1}
                  </Field>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['contact_name2']}>
                    {record.contact_name2}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['contact_phone2']}>
                    {record.contact_phone2}
                  </Field>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={8}>
                  <Field label={labels['contact_name3']}>
                    {record.contact_name3}
                  </Field>
                </Col>
                <Col span={8}>
                  <Field label={labels['contact_phone3']}>
                    {record.contact_phone3}
                  </Field>
                </Col>
              </Row>
            </Block>
          </Col>
        )}
      </Row>
      <Row gutter={[30, 30]}>
        {record && (
          <Col span={24}>
            <Block title="牌價列表" contentStyle={{padding: 0}}>
              <PricingList supplier={record} />
            </Block>
          </Col>
        )}
        {record && (
          <Col span={24}>
            <Block title="廠商帳務列表" contentStyle={{padding: 0}}>
              <SupplierBilling supplier={record} />
            </Block>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
