const processes = {
  印前: {
    label: '印前',
  },
  製版: {
    label: '製版',
  },
  紙張: {
    label: '紙張',
  },
  印刷: {
    label: '印刷',
  },
  上光: {
    label: '上光',
  },
  裁切: {
    label: '裁切',
  },
  軋型: {
    label: '軋型',
  },
  燙金: {
    label: '燙金',
  },
  其他一: {
    label: '其他一',
  },
  其他二: {
    label: '其他二',
  },
  其他三: {
    label: '其他三',
  },
};

export default processes;
