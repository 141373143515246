import React, {useContext} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Pricing from '../Models/Pricing';
import formatValidator from '../Utils/formatValidator';
import {ErrPricing, errorHandler} from '../errors';
import {message, Row, Col} from 'antd';
import processes from '../processes';

const {isNotEmpty} = formatValidator;

const LABELS = Pricing.labels;

export default function PricingForm({record: _record, onUpdate}) {
  const {
    renderInput,
    renderText,
    renderSelect,
    renderCustom,
    record,
  } = useForm({record: _record});
  const app = useContext(Context);
  const edit = !!record.id;

  const valid = () => {
    if (!isNotEmpty(record.process)) {
      throw new ErrPricing('製程不能為空白');
    }
  };

  const submit = async () => {
    const action = edit ? '編輯' : '新增';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      const params = {
        query: edit ? {id: record.id} : {},
        data: record,
      };
      if (edit) {
        await app.actions.editPricing(params);
      } else {
        await app.actions.addPricing(params);
      }

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>牌價資訊</h3>
      <div className="content">
        {/* {renderText(LABELS['id'], 'id')} */}
        {renderText(LABELS['supplier_name'], 'supplier_name')}
        {/* <Row gutter={20}>
          <Col span={16}>
            {renderCustom(LABELS['supplier'], 'supplier', {
              render: ({value}) => (
                <p>{value ? value.slice(0, 20) + '...' : '-'}</p>
              ),
            })}
          </Col>
          <Col span={8}>
            
          </Col>
        </Row> */}

        {renderSelect(LABELS['process'], 'process', {
          options: Object.keys(processes).map((x) => ({label: x, value: x})),
        })}
        {renderInput(LABELS['description'], 'description')}
        {renderInput(LABELS['unit'], 'unit')}
        {renderInput(LABELS['price'], 'price', {
          extraProps: {type: 'number'},
        })}
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
